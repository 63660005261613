import "./Footer.css";
import InstagramGreen from '../../assets/icons/instagram-green.png';
import LinkedinGreen from '../../assets/icons/linkedin-green.png';
import WhatsappGreen from '../../assets/icons/whatsapp-green.png';
//import TwitterGreen from '../../assets/icons/twitter-green.png';
//import FacebookGreen from '../../assets/icons/facebook-green.png';

const Footer = () => {
    /*
    <a href="#" target="_blank" rel="noopener noreferrer">
        <img className="footer-tw-green" src={TwitterGreen} alt="Twitter" />
    </a>
    <a href="#" target="_blank" rel="noopener noreferrer">
        <img className="footer-fb-green" src={FacebookGreen} alt="Facebook" />
    </a>

    */

    return (
        <div className="footer-container" id="home">
            <div className="footer-text">
                Diseño | La Toca<br/>
            </div>
            <div className="footer-icons">
                <a href="https://wa.me/+5492235860982" target="_blank" rel="noopener noreferrer">
                    <img className="footer-wpp-green" src={WhatsappGreen} alt="Whatsapp" />
                </a>
                <a href="https://www.instagram.com/yolo.latam/" target="_blank" rel="noopener noreferrer">
                    <img className="footer-ig-green" src={InstagramGreen} alt="Instagram" />
                </a>
                <a href="https://www.linkedin.com/company/yololatam/" target="_blank" rel="noopener noreferrer">
                    <img className="footer-ln-green" src={LinkedinGreen} alt="Linkedin" />
                </a>
            </div>
        </div>
    );
};

export default Footer;